import React from "react"
import {Layout, Navbar, Download, Footer, Content1, WhyHeader, Content2, Content3} from "../components";

const WhyPage = () => (
    <Layout>
        <Navbar/>
        <WhyHeader/>
        <Content1/>
        <Content2/>
        <Content3/>
        <Download/>
        <Footer/>
    </Layout>
)

export default WhyPage
